import * as SpecPopover from './spec-create';

export const name =
`商品名は、この商品を管理のために区別するための名前です。<br><br>
販売名でも問題ありません。`;

export const isAssort =
  `この商品について、あなたの組織の商品、もしくは購入した商品を単に「複数詰め合わせただけ」の商品場合、「詰め合わせ（アソート）」商品となる可能性があります。
基準としては、詰め合わせることで、①新たな商品となる場合は「通常」表示、②新たな商品とならない場合は「詰め合わせ（アソート）」表示、となります。（参照：食品表示基準QA「加工-247」「加工-248」）
①の例）小袋に分けられた麺とタレを合わせたラーメン
②の例）チョコレートの詰め合わせ、個包装された焼菓子の詰め合わせ
②ついては、それぞれの商品ごとに複数の商品の全ての原材料名を、食品表示基準第3条第1項の表の原材料名の項の規定に基づき表示する必要があります。（参照：食品表示基準QA「（加工-63）」「加工-248」）
また②の場合、栄養成分の表記も異なるため、適切な設定をしてください。（参照：食品表示基準QA「加工-249」）`.replace(/\n/g, '<br>');

export const price =
`この商品の「販売価格」を設定し、原材料にて適切な「1kg当たり原価」を入力している場合、この商品の「原価率」を簡単に計算することができます。`;

export const calorie =
`下記の配合表を入力すると、この商品の栄養成分を表示します。

栄養成分の表示設定中の「表示単位数量」（初期は「100g」）に応じた栄養成分が、表示されます。

適切に表示するためには、使用している原材料の栄養成分が、正しい必要があります。`.replace(/\n/g, '<br>');

export const productItem =
`商品に使用している「原材料」（原材料一覧画面から登録してください）を選択してください。<br>
<br>
添加物についても「原材料」として登録が必要です。<br>
<br>
弁当など、複数の「商品」が入る場合は既に作成した「商品」を選択することも可能です。`;


export const carryover =
`「添加物を栄養強化・加工助剤・キャリーオーバーとして省略する」にチェックを入れることによって、この原材料・商品に含まれる添加物を、原材料名に表示しなくなります。<br>
<br>
添加物を個別に設定したい場合、「個別に設定」を押すことにより、添加物個別に表示を設定することができます。<br>
<br>
ただし、アレルゲン由来の添加物については、一括表示の場合は、特定原材料として残り、個別表示の場合は、添加物そのものが残ります。`;

export const isYieldAffectToNutrition =
`歩留（重量変化）が、加水・蒸発など水分の増減によるものの場合、こちらにチェックを入れてください。

チェックを入れることで、栄養成分の希釈・濃縮などの影響を反映します。`.replace(/\n/g, '<br>');

export const isYieldAsConcentrationReduction =
`歩留まりが、加水によるものの場合、「還元・水戻しによるもの」を選ぶことができます。

これを選んでおり、またこれを使用した商品画面にて「還元後・水戻し後の重量で表示順に反映する」を選択すると、商品の原材料名の表示順が、仕入原材料の仕込量ではなく、還元後・水戻し後の重量（=配合量）で計算が行われます。`.replace(/\n/g, '<br>');

export const settingCalcWithReducedWeight =
`使用している原材料の中に、歩留で「還元・水戻しによるもの」にチェックを入れたものがあった場合、ここにチェックを入れることで、表示順の並び替えを仕入原材料の配合重量ではなく、還元後・水戻し後の重量として反映します。`.replace(/\n/g, '<br>');

export const showLabelSetting =
  `この商品の食品表示ラベルを印刷するためには、ここを「する」にして、各種情報を登録してください。`;

export const labelReferenceProductId =
  `一度、「食品表示ラベルの設定」内容を入力した場合、その商品の内容を引用することで、記入の手間を大幅にラクにすることができます。`;

export const categoryType = '一括表示における、名称区分を選択してください。';

export const category =
  `食品表示ラベルの「名称」で使用する名前です。<br>
<br>
具体的な商品名ではなく、誰が見ても分かる「社会通念上一般的な名前」の必要があります。具体的な商品名ではありません。<br>
<br>
例）<br>
商品名：シェフのオススメハンバーグ<br>
名　称：弁当<br>
<br>
選択肢の中に適切な名称がない場合「自由入力」を選択し、入力してください。`;

export const ingredientText = '自動計算された原材料名が表示されます。';

export const madeInAreaSubtext = '又は表示を行う場合は、原料原産地名（原産国名）の根拠を表示する必要があります';

export const amountType = SpecPopover.labelAmountType;
export const amount = SpecPopover.labelAmount;

export const isExpirationAuto =
`消費・賞味期限の印字内容を設定します。

◆ 印刷ごとに変動

消費・賞味期限が、「YYYY年MM月DD日」のような表示としたい場合は、こちらを設定してください。

印刷時には、「製造日」の設定によって自動的に内容が設定されます。

◆ 固定

消費・賞味期限が、「枠外下部に表示」のように固定表示としたい場合は、こちらを設定してください。
`.replace(/\n/g, '<br>');

export const expirationFormatType = SpecPopover.expirationFormatType;

export const preservationMethod = SpecPopover.labelPreservationMethod;

export const outsideText = SpecPopover.labelOutsideText;

export const nutritionUnit = SpecPopover.nutritionUnit;
export const nutritionAmountPerUnit = SpecPopover.nutritionAmountPerUnit;
export const nutritionGramPerMl = SpecPopover.nutritionGramPerMl;

export const responsibleCompanyType =
`商品を販売するにあたっての「実質的な販売者」を選択してください。

それぞれの選択肢の意味については、以下をご覧ください。

◆ 販売者

この商品を「製造している組織」（製造所）、「加工している組織」（加工所）、「輸入している組織」（輸入者）とは別に、「実質的な販売者」がいる場合は、こちらを選択してください。

例として、A社がOEMを行うB社に依頼して食品を製造、A社が販売を行う場合、A社が販売者となります。

◆ 製造者

この商品の「製造を行っている組織」が、同時にこの商品の「実質的な販売者」である場合は、こちらを選択してください。

◆ 加工者

この商品の「加工を行っている組織」が、同時にこの商品の「実質的な販売者」である場合は、こちらを選択してください。

◆ 輸入者

この商品の「商品の輸入を行っている組織」が、同時にこの商品の「実質的な販売者」である場合は、こちらを選択してください。`.replace(/\n/g, '<br');;

export const responsibleCompanyId =
`販売者を選択します。あなたが所属する組織である場合、「組織プロフィールから引用」となります。

変更したい場合

画面右上 > アカウント設定 > 組織プロフィール

から変更が可能です。`.replace(/\n/g, '<br');

export const responsibleFactoryTypeForSeller =
`上記の「食品表示に責任を負う者」が「販売者」の場合、以下の選択肢があります。

◆ 製造所

この商品が国内で製造され、上のフォームに入力した販売者と異なる組織が製造を担っている場合は、製造所を選択してください。

◆ 製造所固有記号

消費者庁に対して、製造所固有記号の届出をしている場合は、こちらを選択してください。

◆ 加工所

この商品が国内で加工され、上のフォームに入力した販売者と異なる組織が加工を担っている場合は、加工所を選択してください。

◆ 輸入者

この商品の最終製造・加工地が海外で、上のフォームに入力した販売者と異なる組織が輸入を担っている場合は、輸入者を選択してください。`.replace(/\n/g, '<br');

export const responsibleFactoryTypeForManufacturer =
`上記の「食品表示に責任を負う者」が「製造者」の場合、以下の選択肢があります。

◆ 無し

上のフォームに入力した製造者と「同じ住所」で製造されている場合は、こちらを選択してください。

◆ 有り（直接入力）

上のフォームに入力した製造者と「異なる住所」で製造されており、直接入力したい場合はこちらを選択してください。

異なる住所で「製造者」と組織が同じ場合は、住所のみ入力してください。

異なる住所で「組織も異なる」場合は、製造所の組織名と住所を入力してください。

◆ 有り（製造所固有記号）

上のフォームに入力した製造者と「異なる住所」で製造されており、消費者庁に製造所固有記号を提出している場合は、こちらを選んでください。`.replace(/\n/g, '<br');

export const responsibleFactoryTypeForProcessor =
`上記の「食品表示に責任を負う者」が「加工者」の場合、以下の選択肢があります。

◆ 無し

上のフォームに入力した加工者と「同じ住所」で製造されている場合は、こちらを選択してください。

◆ 有り（直接入力）

上のフォームに入力した加工者と「異なる住所」で加工されている場合は、こちらを選んでください。

異なる住所で「加工者」と組織が同じ場合は、住所のみ入力してください。

異なる住所で「組織も異なる」場合は、加工者の組織名と住所を入力してください。`.replace(/\n/g, '<br>');

export const responsibleFactoryNameVisibility =
  `「食品表示に責任を負う者」と製造所（加工所）の組織実体が同一の場合、製造所（加工所）の組織名を省略することができます。`;

export const layoutType =
`こちらから、一括表示の大部分のデザインを設定することができます。<br><br>
具体的な表示については、選択した後に、この画面下部の表示例をご確認ください。`;

export const nutritionLayoutType =
  `こちらから、栄養成分の大部分のデザインを設定することができます。<br><br>
具体的な表示については、選択した後に、この画面下部の表示例をご確認ください。`;

export const fontSize =
`フォントサイズは、法令によって定められています。<br><br>
最低5.5pt以上で、通常は8pt以上となります。`;

export const intraCode = '管理のためにIDが振られている場合は、ここに管理IDを設定してください。';
export const intraCategory =
`自社商品カテゴリから選択することができます。

主に、データ出力の際の並び替え、印刷設定の目的などで、使用することができます。

自社商品カテゴリについては、「管理メンバー」「編集メンバー」に該当するユーザーが、「組織アカウント設定」より、追加・編集・削除することが可能です。`.replace(/\n/g, '<br>');

export const janCode =
`商品に「バーコード（JANコード）」を付与すると、対応レジにてスピーディーに会計をすることができます。

JANコードの発行をご希望される方は、以下のURLからお申し込みください。

https://www.gs1jp.org/code/jan/jan_apply.html

すでに商品の「バーコード（JANコード）」をお持ちの場合は、以下を「有り」にして、13桁のJANコードを入力してください。`.replace(/\n/g, '<br>');

export const identificationMarkType =
`容器包装ごとに識別マークが表示されている場合は、設定の必要はありません。

容器包装ごとに識別マークが表示されていない場合のみ、以下をご覧ください。

プラスチック（1種類のみ）や、紙等を使用している場合は、該当する素材ごとに行を作り、識別マーク、そして「外装」や「ふた」等のように、該当部分を入力してください。

しかし、プラスチック（複数の素材）を使用している場合は、少し複雑になります。

貴社の都合により、以下の表示方法を組み合わせてください。

=========
「プラ」
PP,PE
=========

と表示しPPに下線を引く場合、複数素材を「,」（半角カンマ）区切りで入力してください。主要となる素材は、先に持ってきてください。

=========
「プラ」
キャップ：PE, PP
ボトル：PP
=========

上記のように役割名と材質記号を、改行して表示したい場合は、以下のように2行に分けて入力してください。

【項 目】識別マーク、部位名、包材の材質記号・規格
【1行目】プラマーク、キャップ、PE, PP
【2行目】プラマーク、ボトル、PP`.replace(/\n/g, '<br>');

export const identificationMaterialNames =
`「包材の部位名」に対して、複数材質ある場合は、複数選択してください。

詳細は、左記にある「識別マーク」の「？」（ヘルプマーク）内をご覧ください。`.replace(/\n/g, '<br>');

export const tag = 'この商品の管理用のタグを設定すると、検索や整理などが効率よく行えます。';

export const showIdentificationMark =
`商品に「プラスチック」「紙」の容器包装を使った場合、識別マーク（プラマーク、紙マーク）を表示することが義務となっています。

あらかじめ使用する容器包装に識別マークがついていない場合は、こちらを選択する必要があります。
`.replace(/\n/g, '<br>');
