import IngredientEntity from "@/entities/ingredient-entity";

import {IPaginator} from "@/contracts/i-paginator";
import IngredientRepository from "@/repositories/company/ingredient-repository";
import {IResourceBelongingToDepartment} from "@/services/company-department-service";
import CompanyDepartmentEntity from "@/entities/company-department-entity";
import ObjectUtils from "@/utils/object-utils";

export default class PreproductRepository extends IngredientRepository {
  public async findById(id, params = {}): Promise<IngredientEntity>  {
    const res = await this.get(`companies/${this.companyId}/preproducts/${id}`, params);
    return new this.ctor(res.data);
  }

  public async searchPreproduct(query, params): Promise<IPaginator<PreproductForIndex>>  {
    const p = Object.assign({ _as_pagination: true, q:query }, params);
    const res = await this.get(`companies/${this.companyId}/preproducts/search`, p);
    return this.toPagination(res, PreproductForIndex);
  }
}

export class PreproductForIndex implements IResourceBelongingToDepartment {
  public id!: number;
  public companyOrd!: number;
  public name!: string;
  public intraCode!: string|null;
  public displayName!: boolean;
  public tags!: string[];
  public departments!: CompanyDepartmentEntity[];

  constructor(init:PreproductForIndex) {
    ObjectUtils.assignLiteralFields(this, init);
    this.tags = init.tags || [];
    this.departments = init.departments || [];
  }

  public getDepartmentIds(): number[] {
    return this.departments.map(d => d.id);
  }
}
