import * as Ja from './ja/product-create';
import * as En from './en/product-create';
import * as Cn from './cn/product-create';
import {PartnerType} from "@/entities/specs/partner-entity";
import {getTextByLang} from "@/bootstraps/locale";
import {responsibleFactoryNameVisibility} from "./ja/product-create";

export const help = {
  name: getTextByLang({
    ja: Ja.name,
    en:En.name,
    cn:Cn.name
  }),
  isAssort: getTextByLang({
    ja: Ja.isAssort,
  }),
  price: getTextByLang({
    ja: Ja.price,
    en:En.price,
    cn:Cn.price
  }),
  calorie: getTextByLang({
    ja: Ja.calorie,
    en:En.calorie,
    cn:Cn.calorie
  }),
  productItem: getTextByLang({
    ja: Ja.productItem,
    en:En.productItem,
    cn:Cn.productItem
  }),
  carryover: getTextByLang({
    ja: Ja.carryover,
    en:En.carryover,
    cn:Cn.carryover
  }),
  isYieldAffectToNutrition: getTextByLang({
    ja: Ja.isYieldAffectToNutrition,
  }),
  isYieldAsConcentrationReduction: getTextByLang({
    ja: Ja.isYieldAsConcentrationReduction,
  }),
  settingCalcWithReducedWeight: getTextByLang({
    ja: Ja.settingCalcWithReducedWeight,
  }),
  showLabelSetting: getTextByLang({
    ja: Ja.showLabelSetting,
    en:En.showLabelSetting,
    cn:Cn.showLabelSetting
  }),
  labelReferenceProductId: getTextByLang({
    ja: Ja.labelReferenceProductId,
    en:En.labelReferenceProductId,
    cn:Cn.labelReferenceProductId
  }),
  categoryType: getTextByLang({
    ja: Ja.categoryType,
    en:En.categoryType,
    cn:Cn.categoryType
  }),
  category: getTextByLang({
    ja: Ja.category,
    en:En.category,
    cn:Cn.category
  }),
  ingredientText: getTextByLang({
    ja: Ja.ingredientText,
    en:En.ingredientText,
    cn:Cn.ingredientText
  }),
  madeInAreaSubtext: getTextByLang({
    ja: Ja.madeInAreaSubtext,
    en:En.madeInAreaSubtext,
    cn:Cn.madeInAreaSubtext
  }),
  amountType: getTextByLang({
    ja: Ja.amountType,
    en:En.amountType,
    cn:Cn.amountType
  }),
  amount: getTextByLang({
    ja: Ja.amount,
    en:En.amount,
    cn:Cn.amount
  }),
  isExpirationAuto: getTextByLang({
    ja: Ja.isExpirationAuto,
    en:En.isExpirationAuto,
    cn:Cn.isExpirationAuto
  }),
  expirationFormatType: getTextByLang({
    ja: Ja.expirationFormatType,
    en:En.expirationFormatType,
    cn:Cn.expirationFormatType
  }),
  preservationMethod: getTextByLang({
    ja: Ja.preservationMethod,
    en:En.preservationMethod,
    cn:Cn.preservationMethod
  }),
  outsideText: getTextByLang({
    ja: Ja.outsideText,
    en:En.outsideText,
    cn:Cn.outsideText
  }),
  nutritionUnit: getTextByLang({
    ja: Ja.nutritionUnit,
    en:En.nutritionUnit,
    cn:Cn.nutritionUnit
  }),
  nutritionAmountPerUnit: getTextByLang({
    ja: Ja.nutritionAmountPerUnit,
    en:En.nutritionAmountPerUnit,
    cn:Cn.nutritionAmountPerUnit
  }),
  nutritionGramPerMl: getTextByLang({
    ja: Ja.nutritionGramPerMl,
    en:En.nutritionGramPerMl,
    cn:Cn.nutritionGramPerMl
  }),
  responsibleCompanyType: getTextByLang({
    ja: Ja.responsibleCompanyType,
    // TODO: LANG
  }),
  responsibleCompanyId: getTextByLang({
    ja: Ja.responsibleCompanyId,
    // TODO: LANG
  }),
  responsibleFactoryTypeForSeller: getTextByLang({
    ja: Ja.responsibleFactoryTypeForSeller,
    // TODO: LANG
  }),
  responsibleFactoryTypeForManufacturer: getTextByLang({
    ja: Ja.responsibleFactoryTypeForManufacturer,
    // TODO: LANG
  }),
  responsibleFactoryTypeForProcessor: getTextByLang({
    ja: Ja.responsibleFactoryTypeForProcessor,
    // TODO: LANG
  }),
  responsibleFactoryNameVisibility: getTextByLang({
    ja: Ja.responsibleFactoryNameVisibility,
    // TODO: LANG
  }),
  layoutType: getTextByLang({
    ja: Ja.layoutType,
    en:En.layoutType,
    cn:Cn.layoutType
  }),
  nutritionLayoutType: getTextByLang({
    ja: Ja.nutritionLayoutType,
    // TODO: LANG
  }),
  fontSize: getTextByLang({
    ja: Ja.fontSize,
    en:En.fontSize,
    cn:Cn.fontSize
  }),
  intraCode: getTextByLang({
    ja: Ja.intraCode,
    en:En.intraCode,
    cn:Cn.intraCode
  }),
  intraCategory: getTextByLang({
    ja: Ja.intraCategory,
    // TODO: LANG
  }),
  janCode: getTextByLang({
    ja: Ja.janCode,
    // TODO: LANG
  }),
  identificationMarkType: getTextByLang({
    ja: Ja.identificationMarkType,
    // TODO: LANG
  }),
  identificationMaterialNames: getTextByLang({
    ja: Ja.identificationMaterialNames,
    // TODO: LANG
  }),
  tag: getTextByLang({
    ja: Ja.tag,
    // TODO: LANG
  }),
  showIdentificationMark: getTextByLang({
    ja: Ja.showIdentificationMark,
    // TODO: LANG
  }),
  getResponsibleFactoryType: getResponsibleFactoryType,
};

export function getResponsibleFactoryType(type: PartnerType): string {
  switch(type) {
    case PartnerType.Seller: return help.responsibleFactoryTypeForSeller;
    case PartnerType.Manufacturer: return help.responsibleFactoryTypeForManufacturer;
    case PartnerType.Processor: return help.responsibleFactoryTypeForProcessor;
    case PartnerType.Importer: return '';
  }
}
