import * as SpecPopover from './spec-create';
import {labelAmountType} from "./spec-create";

export const name =
`商品名称是为了管理方便而进行区分本商品的名称。<br><br>
也可以是销售名称`;


export const price =
`如果您为该产品设置了“销售价格”，并为原材料输入了相应的“每公斤的成本”，您就可以轻松计算出该商品的“成本率”。`;

export const calorie =
` 输入下面的配方表，以显示该商品“每100g”的营养成分。<br>
<br>
为了正确标示，必须正确输入所用原材料的营养成分。<br>
`;

export const productItem =
`选择商品使用的“原材料”（请从原材料一览中进行登陆）。<br>
<br>
添加剂也需要登陆为“原材料”。<br>
<br>
像盒饭之类的，如果您有多个“商品”，您也可以选择已经创建的“商品。`;


export const carryover =
`通过勾选“省略作为强化剂/加工助剂/残留物的添加剂”，将不会在原材料名称中显示该原材料/商品中所含的添加剂。<br>
<br>
如果您想单独设置添加剂，您可以通过按“单独设置”来设置每个添加剂的显示。<br>
<br>
但是，对于过敏原衍生的添加剂，在批量显示的情况下，它们作为特定的原材料存在，而在单独标示的情况下，它们作为添加剂本身存在
。`;


export const showLabelSetting =
  `为了打印本商品的食品标示标签，请在此处设置为“是”，并登陆各种信息。`;

export const labelReferenceProductId =
  `一旦您输入了“食品标示标签设置”的内容，您可以通过引用商品内容来大大减少填写的时间和精力。`;

export const categoryType = '在批量显示中选择名称类别。';

export const category =
  `这是食品标示标签的“名称”中使用的名字。<br>
<br>
必须是一个任何人看到后都能理解的“社会上通用的名字”，而不是商品的具体名称。不是具体的商品名称。<br>
<br>
例）<br>
商品名：厨师推荐的汉堡<br>
名　称：便当<br>
<br>
如果选项中没有合适的名称，请选择“自由输入”进行输入。`;

export const ingredientText = '将显示自动计算出的原材料名称。';

export const madeInAreaSubtext = '或者在显示时，需要显示原材料的原产地（原产国名）的依据。';

export const amountType = SpecPopover.labelAmountType;
export const amount = SpecPopover.labelAmount;

export const isExpirationAuto =
`设置消费/有效日期的打印内容。

◆ 因每次印刷而异

如果您想将消费/有效日期显示为“YYYY年MM月DD日”，请设置此项。

打印时，根据“制造日”的内容将自动被设置。

◆ 固定

如果想像“显示于框外的底部”一样，将消费/有效日期设置为固定显示，请设置此项。
`.replace(/\n/g, '<br>');

export const expirationFormatType = SpecPopover.expirationFormatType;

export const preservationMethod = SpecPopover.labelPreservationMethod;

export const extras = {
  precaution: SpecPopover.labelPrecaution,
  howToCook: SpecPopover.labelHowToCook,
  usage: SpecPopover.labelUsage,
  sanitizeMethod: SpecPopover.labelSanitizeMethod,
  heatedBeforeFrozen: SpecPopover.labelHeatedBeforeFrozen,
  needHeating: SpecPopover.labelNeedHeating,
  starchRatio: SpecPopover.labelStarchRatio,
}

export const milkNonfatSolidRatio = SpecPopover.labelMilkNonfatSolidRatio;
export const customValues = SpecPopover.customValues;

export const outsideText =
`● 开封后请尽快食用。
● 该商品的生产线包含鸡蛋、小麦和花生的商品生产。
等，如果想要在批量显示之外显示以下“备注”，请在此处进行输入。`.replace(/\n/g, '<br>');

export const nutritionUnit = SpecPopover.nutritionUnit;
export const nutritionAmountPerUnit = SpecPopover.nutritionAmountPerUnit;
export const nutritionGramPerMl = SpecPopover.nutritionGramPerMl;

export const seller =
`选择销售方。如果是您所属的组织，则是“从组织简介中引用”。

想要进行修改的时候

可以从 屏幕右上角> 帐户设置> 组织简介

进行修改。

如果以下“制造商/加工商/进口商”的任意一项，和销售商相同的时候，销售商的显示将被省略。
`.replace(/\n/g, '<br');

export const layoutType =
`从这里您可以对批量显示的大部分设计进行设置。 <br><br>
具体的显示，请在选择后确认此屏幕底部的显示示例。`;

export const fontSize =
`关于字体的大小，根据相关法律有所规定。<br><br>
最少为5.5pt 以上，通常为8pt以上。`;

export const intraCode = '如果已分配有管理ID，请在此处设置管理ID。';
