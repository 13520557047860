









































































import { Component, Prop} from 'vue-property-decorator';

import {IFindOption} from "@/repositories/repository-base";
import InputSearch from '@/views/label/companies/components/InputSearch.vue';
import PreproductRepository from "@/repositories/company/preproduct-repository";
import ListPageBase from "@/views/label/companies/ListPageBase";
import DepartmentAnnotationOnIndex from "@/views/components/Department/DepartmentAnnotationOnIndex.vue";
import EditableSlot from "@/views/components/Department/EditableSlot.vue";

@Component({
  components: {
    EditableSlot,
    DepartmentAnnotationOnIndex,
    InputSearch
  }
})
export default class extends ListPageBase {
  protected fetchIngredientCount:boolean = true;

  protected getRepository() {
    return new PreproductRepository(this.companyId);
  }

  protected async find(searchText, opt:IFindOption) {
    return await this.getRepository().searchPreproduct(searchText, opt)
  }

  protected async onCreating() {
    this.routeName = 'preproduct';
  }
}
