import * as SpecPopover from './spec-create';


export const name =
`Product name is used to distinguish this product for management purposes.<br><br>
The packaging name is also valid.`;




export const price =
`If you have set the "selling price" for this product and entered the appropriate "cost per kg" in the ingredients, you can easily calculate the "Benefit-cost ratio" for this product.`;


export const calorie =
` Enter the formula table below to view the "per 100g" nutritional information for this product.<br>
<br>
In order to be properly labeled, the nutritional content of the ingredients must be correct.<br>
`;


export const productItem =
`Select the "ingredients" used in the product (please register them from the ingredients list screen).<br>
<br>
Additives must also be registered as " ingredients".<br>
<br>
If there is more than one "product" to be included, such as bento, you may select the " products" you have already created.`;




export const carryover =
`By checking the " Omit additives as nutrient enrichment, processing aid, or carry-over of food additives" checkbox, additives contained in this ingredient or product will not be listed in the ingredient name.<br>
<br>
If you want to set up individual additives, you can set up the display for each additive by pressing "Set individually".<br>
<br>
However, allergen-derived additives will remain as specified ingredients in the case of bulk labeling, and in the case of individual labeling, the additives themselves will remain.`;




export const showLabelSetting =
  `To print out the food label for this product, please select "Yes" here and register the information.`;


export const labelReferenceProductId =
  `If you have entered the contents of the "Food Label Settings" already, you can quote the contents for that product to save time in filling out the form.`;


export const categoryType = 'Please select the name category for batch display.';


export const category =
  `This name is used in the "Name" section of the food label.<br>
<br>
It should not be a specific product name, but a "socially accepted common name" that anyone can recognize.<br>
<br>
Example）<br>
Product name: Chef's recommended hamburger steak<br>
Name：Bento<br>
<br>
If there is no appropriate name among the options, please select "Free Input" and enter it.`;


export const ingredientText = 'The auto-calculated ingredient names will be displayed.';


export const madeInAreaSubtext = 'In the case of displaying, the name of ingredient origin ( country of origin) must be indicated.';


export const amountType = SpecPopover.labelAmountType;
export const amount = SpecPopover.labelAmount;


export const isExpirationAuto =
`Sets the printing contents for consumption and expiration dates.


◆ Changes for every print


If you want the expiration date to be displayed as "YYYY MM/DD", please set this option.


When printing, the contents will be automatically set based on the "Production Date" setting.




◆ Fixed print


If you want the expiration date to be fixed, such as "See top/bottom/side of package," set this option.
`.replace(/\n/g, '<br>');


export const expirationFormatType = SpecPopover.expirationFormatType;


export const preservationMethod = SpecPopover.labelPreservationMethod;


export const extras = {
  precaution: SpecPopover.labelPrecaution,
  howToCook: SpecPopover.labelHowToCook,
  usage: SpecPopover.labelUsage,
  sanitizeMethod: SpecPopover.labelSanitizeMethod,
  heatedBeforeFrozen: SpecPopover.labelHeatedBeforeFrozen,
  needHeating: SpecPopover.labelNeedHeating,
  starchRatio: SpecPopover.labelStarchRatio,
}


export const milkNonfatSolidRatio = SpecPopover.labelMilkNonfatSolidRatio;
export const customValues = SpecPopover.customValues;


export const outsideText =
`● once opened consume immediately.
● This product is manufactured on a production line that includes products containing eggs, wheat, and peanuts.


If you want to display the following " Notes" outside the batch display, please fill in here.`.replace(/\n/g, '<br>');


export const nutritionUnit = SpecPopover.nutritionUnit;
export const nutritionAmountPerUnit = SpecPopover.nutritionAmountPerUnit;
export const nutritionGramPerMl = SpecPopover.nutritionGramPerMl;


export const seller =
`Please select the seller. If it is an organization you belong to, it will be "Cited from organization profile".


If you want to make changes


Top right corner of the screen > Account Settings > Organization Profile


Also, if one of the forms below "Manufacturer, Processor, Importer" is the same as the seller's, indication of the seller will be omitted.`.replace(/\n/g, '<br');


export const layoutType =
`You can set the design of most of the bulk displays from here.<br><br>
For the specific display, please check the display example at the bottom of this screen after making your selection.`;


export const fontSize =
`Font size is defined by law.<br><br>
The minimum is 5.5 pts, and the standard is 8 pts or more.`;


export const intraCode = 'If ID has been assigned for the management, please set the management ID here.';
