








































































































import { Component, Prop} from 'vue-property-decorator';
import LoadingHandler from '@/utils/loading-handler';

import IngredientEntity, {ValidatorRules} from '@/entities/ingredient-entity';
import IngredientRepository, {IngredientListType} from '@/repositories/company/ingredient-repository';

import IngredientItemEntity from "@/entities/ingredient-item-entity";

import StickyHeader from '@/components/StickyHeader.vue';
import MadeInAreaSection from '../ingredient/components/MadeInAreaSection.vue';
import ItemSelectTable from '../product/components/ItemSelectTable.vue';

import FooterNote from "@/views/label/companies/components/FooterNote.vue";
import FooterAttachment from "@/views/label/companies/components/FooterAttachment.vue";
import FooterHistory from "@/views/label/companies/components/FooterHistory.vue";
import FooterReferencedProducts from '../components/FooterReferencedProducts.vue';
import TagSelect from '@/components/TagSelect.vue';

import { help as PopoverText } from '@/lang/help/ingredient-create';
import {TItemGroupItem} from "@/views/label/companies/product/components/ItemSelectTable.vue";
import ProductDisplayService from "@/services/product-display-service";

import ObjectUtils from "@/utils/object-utils";

import cloneDeep from 'lodash/cloneDeep';
import PreproductRepository from "@/repositories/company/preproduct-repository";
import CreateLabelPageBase from "@/views/label/companies/CreateLabelPageBase";
import CompanyEntity, {CompanyResourceTagType} from "@/entities/company-entity";
import NutritionSection from "@/components/Project/nutrition/NutritionSection.vue";
import { CompanyRepository } from '@/repositories/company-repository';
import ResourceDepartmentSelectSection from "@/views/components/Department/ResourceDepartmentSelectSection.vue";
import {AccessLogRepository} from "@/repositories/access-log-repository";
import IconLock from "@/components/Icons/IconLock.vue";
import LockButton from "@/views/label/companies/components/LockButton.vue";
import {dispatchInitialized} from "@/libs/cypress";

@Component({
  components: {
    LockButton,
    IconLock,
    ResourceDepartmentSelectSection,
    NutritionSection,
    StickyHeader,
    MadeInAreaSection,
    ItemSelectTable,
    FooterNote,
    FooterAttachment,
    FooterHistory,
    FooterReferencedProducts,
    TagSelect
  }
})
export default class extends CreateLabelPageBase {
  protected ctor = IngredientEntity;
  protected titleLabel:string = this.$t('中間原材料');
  protected routePrefix:string = 'preproduct';
  protected model:IngredientEntity = null as any;
  private company: CompanyEntity = null as any;

  private readonly PopoverText = PopoverText;
  private readonly CompanyTagType = CompanyResourceTagType;

  private selection:TItemGroupItem[] = [];

  private get rules(){
    return ValidatorRules;
  }
  private get isAnyItemSelected() {
    return this.model && this.model.ingredientItems.some(pi => !!pi.item);
  }

  protected getRepository() {
    return new IngredientRepository(this.companyId);
  }

  protected findById(id): Promise<any> {
    return (new PreproductRepository(this.companyId)).findById(id);
  }

  private async created() {
    const promises:any = [
      this.initializeModel().then((model: IngredientEntity) => {
        this.model = model;
        this.model.isPreproduct = true;

        // first item
        if (!this.model!.ingredientItems.length) {
          this.model!.ingredientItems.push(new IngredientItemEntity({}, this.model!));
        }
        // set user's departments
        if (this.isCreateMode) {
          this.model.departmentIds = this.$auth.user.departments.map(d => d.id);
        } else {
          (new AccessLogRepository(this.companyId)).logIngredient(this.id!);
        }
        return this.model;
      }),
      (new IngredientRepository(this.companyId)).list(this.id),
      new CompanyRepository().find(this.companyId).then((company) => {
        this.company = company;
      }),
    ];

    LoadingHandler(() => Promise.all(promises).then((data) => {
      const { ings, preproducts } = this.getOldSelectionAppendedResources(data[0] as IngredientEntity, data[1] as IngredientListType[]);
      this.setSelections(ings, preproducts);
      this.isAllLoaded = true;
      dispatchInitialized();
    }));
  }

  // （部署設定により）listで取得できなかったProductItemsをFillする
  private getOldSelectionAppendedResources(model: IngredientEntity, ingredients: IngredientListType[]) {
    const ings = ingredients.filter(i => !i.isPreproduct);
    const preproducts = ingredients.filter(i => i.isPreproduct);
    model.ingredientItems.forEach(pi => {
      if (!pi.ingredient) return;
      if (!ingredients.find(i => i.id === pi.ingredientId)) {
        const data = {...pi.ingredient, name: `（他部署）${pi.ingredient.name}`};
        if (pi.ingredient.isPreproduct) {
          preproducts.push(data);
        } else {
          ings.push(data);
        }
      }
    });
    return { ings, preproducts };
  }

  private setSelections(ings: IngredientListType[], preproducts: IngredientListType[]) {
    const parse = (d, prefix) => ({
      key: prefix + d.id,
      id: d.id,
      name: d.displayName ? `${d.name}（${d.displayName}）` : d.name,
      search: d.name + (d.displayName || '') + d.intraCode + d.tags.join(''),
    });
    this.selection.push({
      key: '原材料',
      name: this.$t('原材料'),
      search: this.$t('原材料'),
      isHeading: true
    });
    this.selection.push(...ings.map(d => parse(d, IngredientEntity.KEY_PREFIX)));
    this.selection.push({
      key: '中間原材料',
      name: this.$t('中間原材料'),
      search: this.$t('中間原材料'),
      isHeading: true
    });
    this.selection.push(...preproducts.map(d => parse(d, IngredientEntity.KEY_PREFIX_PREPRODUCT)));
  }

  private onItemChanged(ingredientItem: IngredientItemEntity) {
    if (ingredientItem.childIngredient!.isPreproduct) {
      ingredientItem.isCompositeSplitted = true;
    } else {
      ingredientItem.isCompositeSplitted = null;
    }

    ingredientItem.carryoverHandler.items.filter(i => i.hidden).forEach(i => i.isCarryover = true);
  }

  private getIngredientNames() {
    return ProductDisplayService.getIngredientNamesByItemsForIngredient(this.model!, this.company!.setting);
  }

  // region Submit
  private async submit() {
    const data = this.serialize();
    await this.submitData(data);
  }

  private serialize(): IngredientEntity {
    const data = cloneDeep(this.model!) as IngredientEntity;

    data.ingredientItems = data.ingredientItems
      .filter(ii => !!ii.item && ii.amount !== null) // remove empty
      .map(ii => {
        return Object.assign(ObjectUtils.excludeObject(ii), { carryovers: ii.carryoverHandler.serialize() });
      }) as any;
    data.ingredientDisplaySetting.isIngredientItemAmountPercent = false;
    if (data.isNutritionOverrideOnPreproduct) {
      data.nutrition = data.nutrition.serialize();
    } else {
      data.nutrition = undefined as any;
    }

    data.attachments = data.attachments.filter(a => a.isAdded || a.__destroy);

    if (!data.isYieldEnabled) {
      data.yieldPercent = null as any;
      data.isYieldAffectToNutrition = false;
      data.isYieldAsConcentrationReduction = false;
    }

    return data;
  }

  // endregion
}
